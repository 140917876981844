@font-face {
    font-family: "Copper Penny DTP";
    src: url("../images/fonts/Copper-Penny-DTP.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

body {
    position: static !important;
}
.user_mode {
    .site-background {
        top: 0;
        background-size: cover;
    }
    #topNav,
    #topNavPlaceholder,
    .snFooterContainer {
        display: none;
    }
    #panelTwo:before {
        background: transparent;
        box-shadow: none;
    }
    .layoutContainer {
        background: transparent;
    }
    .logo-container {
        .heroPhotoElement {
            img {
                max-width: 155px;
            }
        }
    }
    .text-container {
        .nysa-logo {
            .heroPhotoElement {
                img {
                    max-width: 605px;
                }
            }
        }
        .nysa-text {
            .heroPhotoElement {
                img {
                    max-width: 1065px;
                }
            }
        }
    }
    .custom-link-container {
        &:after {
            content: '';
            background: rgba(0,0,0,0.5);
            display: block;
            position: absolute;
            height: 225px;
            margin-top: 46px;
            width: 100%;
            z-index: 1;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
        .column {
            z-index: 100;
            .novato-logo {
                a {
                    img {
                        max-width: 395px;
                        @media screen and (max-width: $breakpoint-sm-max) {
                            width: 50%;
                        }
                    }
                }
            }
            .novato-text {
                padding-top: 62px;
                @media screen and (max-width: $breakpoint-sm-max) {
                    padding-top: 0;
                }
            }
            .north-logo {
                a {
                    img {
                        max-width: 305px;
                        @media screen and (max-width: $breakpoint-sm-max) {
                            width: 50%;
                        }
                    }
                }
            }
            .north-text {

            }
            .textBlockElement {
                h3 {
                    color: #fff;
                    text-transform: uppercase;
                    font-family: 'Copper Penny DTP';
                    font-weight: normal;
                    @media screen and (max-width: $breakpoint-sm-max) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

